<template>
    <div class="business_bg_img_1">
        <div class="business_text_1">
            <span class="business_text_title">藤壶生活圈小程序<br> </span>
            <span class="business_text_content">入驻商户超400+家,商务团队200+人</span>
            <img src="../assets/foot 小程序.png"  class="business_qrcode"  >
            <span class="business_qrcode_text">扫码体验</span>
        </div>
    </div>
    <div class="business_bg_img_2">
        <div class="business_text_2">
            <span class="business_text_title_center">藤壶圈小程序2.0上线啦<br> </span>
            <span class="business_text_content_center">智慧消费新体验,共创美好生活圈!<br>餐饮美食、百货超市等多元化店铺,轻松发现精彩纷呈!<br></span>
        </div>
    </div>
    <div class="business_bg_img_3">
        <div class="business_text_3">
            <span class="business_text_title">邀请新人福利享不停<br> </span>
            <span class="business_text_content">邀请好友注册消费,双方均可获得丰厚奖励,<br>惊喜不断</span>
        </div>
    </div>
    <div class="business_bg_img_4">
        <div class="business_text_4">
            <span class="business_text_title">签到领抵用券<br> </span>
            <span class="business_text_content">每日签到可领现金抵用券,签的越多获得越多</span>
        </div>
    </div>
    <div class="business_bg_img_5">
        <div class="business_text_5">
            <span class="business_text_title">商家使用安心便捷<br> </span>
            <span class="business_text_content">消费资金第三方平台监管,新客户消费即可锁粉得抵用券<br>享受终身受益</span>
        </div>
    </div>
    <div class="business_bg_img_6">
        <div class="business_text_6">
            <span class="business_text_title">商务人员<br></span>
            <span class="business_text_content">商务人员可实时查看业绩及级别数据</span>
        </div>
    </div>
</template>
<style lang="postcss">
@import url('../css/business.css');
</style>