<template>
    <div class="mbnews-detail-container">
        <h1 class="mbnews-title">{{ cultureData.title }}</h1>
        <p class="mbnews-date">{{ cultureData.createdAt }}</p>
        <img :src="cultureData.thumbnail" class="mbnews-img" alt="详情图片">
        <div class="mbnews-content" v-html="cultureData.content"></div>
    </div>
</template>


<script setup>
import { useRoute } from 'vue-router';
import { reactive } from 'vue';

const route = useRoute();
const cultureData = reactive(JSON.parse(route.params.cultureData || route.query.mbcultureData));
</script>

<style>
.mbnews-detail-container {
    width: 80%;
    height: 100%;
    margin: 0 auto;
}

.mbnews-title {
    padding-top: 50px;
    font-size: 24px;    
    color: #000;
    /* 标题颜色为黑色，可根据需求调整 */
    text-align: left;
    margin: 0;
    /* 去除默认外边距 */
}

.mbnews-date {
    font-size: 12px;
    color: #888;
    /* 灰色 */
    text-align: left;
    margin: 5px 0;
    /* 上下添加一点外边距，使排版更美观 */
}
.mbnews-img {
    width: 80vw;
    height: 40vh;
    margin-bottom: 10px;
    /* 图片下方添加一点间距，和下方内容隔开 */
}

.mbnews-content {
    text-align: left;
    padding-top: 30px;
    padding-bottom: 60px;
}
</style>