<template>
    <div class="mbbusiness_bg_img_1"/>
    <div class="mbbusiness_bg_img_2"/>
    <div class="mbbusiness_bg_img_3"/>
    <div class="mbbusiness_bg_img_4"/>
    <div class="mbbusiness_bg_img_5"/>
    <div class="mbbusiness_bg_img_6"/>
</template>
<style lang="postcss">
@import url('../css/mb/business.css');
</style>