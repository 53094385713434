<template>
    <div class="culture">
        <div class="culture_groups">
            <div class="culture_group" v-for="culture in companyCultures" :key="culture.id">
                <img :src="culture.thumbnail" class="culture_img">
                <span class="culture_text_title">{{ culture.title }}</span>
                <span class="culture_text_content" v-html="processContent(culture.content)"></span>
                <router-link class="newDetailBtn"
                    :to="{ name: 'newsDetail', params: { cultureData: JSON.stringify(culture) } }">
                    <span>查看详情</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

 
<script setup>
import { ref, onMounted ,getCurrentInstance } from 'vue';
import axios from 'axios'; // 引入axios库，确保项目中已经安装了axios依赖，如果没安装需要先安装（npm install axios）
import { ElMessage } from 'element-plus'; // 假设这里使用element-plus的消息提示组件，根据实际情况调整

const companyCultures = ref([]);
const { proxy } = getCurrentInstance();


function processContent(content) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const pElements = doc.querySelectorAll('p');

    if (pElements.length > 0) {
        const firstPElement = pElements[0];
        const text = firstPElement.textContent.trim().replace(/\s+/g, '');
        if (text.length > 40) {
            const truncatedText = text.slice(0, 40) + '...';
            return truncatedText;
        }

    }
    return content;
}


// 在onMounted钩子函数中发送请求获取数据
onMounted(() => {
    const data = {
        pageNum: 1,
        pageSize: 3,
        orderByColumn: "createdAt",
        isAsc: 'asc'
    };
    axios.get(proxy.$BASE_URL + '/companyCulture/list', {
        params: data // 使用params选项传递查询参数，axios.get请求传递参数需要这样写
    })
        .then(response => {
            const resultData = response.data.data.result;
            // 将获取到的数据赋值给companyCultures
            companyCultures.value = resultData;
        })
        .catch(error => {
            ElMessage.error('获取公司文化数据失败，请稍后再试'); // 更具体的提示信息，方便用户了解情况
            console.error(error); // 可以在控制台打印具体的错误信息，方便排查问题
        });
});
</script>

<style>
@import url(../css/news.css);
</style>
