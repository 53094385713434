<template>
  <div class="mbabout_banner" />
  <div class="mbabout_company">
    <span class="mbabout_company_title">公司介绍</span>
    <span
      class="mbabout_company_content">&emsp;&emsp;江苏藤壶数字科技有限公司成立于2024年，一个创新型的数字经济公司。致力于打造消费生活数字平台——藤壶生活圈。运用先进的经济理论和技术手段，整合线上线下资源，为消费者提供全新的消费体验。聚焦消费者核心需求，联合餐饮美食、美容家装、休闲养生等多行业实体商家，构建新型消费服务体系。通过藤壶生活圈平台，消费者能享受更便捷的消费服务，同时实现家庭收入的持续增长和多元化致富。公司以创新引领发展，不断探索数字经济时代的美好生活新路径。</span>
    <div class="mbabout_group_inner_container">
      <div class="mbabout_group">
        <span class="mbabout_group_num">50000+</span>
        <span class="mbabout_group_title">会员数量</span>
      </div>
      <div class="mbabout_group">
        <span class="mbabout_group_num">400+</span>
        <span class="mbabout_group_title">商家数量</span>
      </div>
      <div class="mbabout_group">
        <span class="mbabout_group_num">1000万+</span>
        <span class="mbabout_group_title">总交易额</span>
      </div>
    </div>
  </div>
  <div class="mbabout_img1" />
  <div class="mbabout_img2" />
  <div class="mbabout_img3" />

</template>

<script setup>
</script>

<style lang="postcss">
@import url('../css/mb/about.css');
</style>
