<template>
    <div class="news-detail-container">
        <h1 class="news-title">{{ cultureData.title }}</h1>
        <p class="news-date">{{ cultureData.createdAt }}</p>
        <img :src="cultureData.thumbnail" class="news-img" alt="详情图片">
        <div class="news-content" v-html="cultureData.content"></div>
    </div>
</template>


<script setup>
import { useRoute } from 'vue-router';
import { reactive } from 'vue';

const route = useRoute();
const cultureData = reactive(JSON.parse(route.params.cultureData || route.query.cultureData));
</script>

<style>
.news-detail-container {
    width: 80%;
    height: 100%;
    margin: 0  auto;
    margin-top: 40px;
}

.news-title {
    padding-top: 50px;
    font-size: 24px;    
    color: #000;
    /* 标题颜色为黑色，可根据需求调整 */
    text-align: left;
    margin: 0;
    /* 去除默认外边距 */
}

.news-date {
    font-size: 12px;
    color: #888;
    /* 灰色 */
    text-align: left;
    margin: 5px 0;
    /* 上下添加一点外边距，使排版更美观 */
}
.news-img {
    width: 50vw;
    height: 80vh;
    margin-bottom: 10px;
    /* 图片下方添加一点间距，和下方内容隔开 */
}

.news-content {
    text-align: left;
    padding-top: 30px;
    padding-bottom: 60px;
}
</style>