<template>
  <div class="box flex-row">
    <div class="box_1">
      <img class="image_1" referrerpolicy="no-referrer" @click="goToHomePage" src="../../assets/logo.png" />
    </div>
    <div class="box_2">
      <el-dropdown trigger="click">
        <el-button type="text" class="el-dropdown-link" @click="handleDropdownClick"><i
            class="el-icon-arrow-down el-icon--right"> <img class="button-icon" src="../../assets/header-更多图标.png" alt="图标描述">
          </i></el-button>
        <template #dropdown>
          <el-menu>
            <el-menu-item index="1-1" :to="{ path: '/mbHomePage' }">
              <router-link class="text_header" to="/mbHomePage"
                v-active-link="{ path: '/mbHomePage', extraClass: 'highlighted' }" active-class="active-link"
                exact-active-class="exact-active-link">
                <span class="text_header">藤壶首页</span>
              </router-link>
            </el-menu-item>
            <el-menu-item index="1-2" :to="{ path: '/mbBusiness' }">
              <router-link class="text_header" to="/mbBusiness"
                v-active-link="{ path: '/mbBusiness', extraClass: 'highlighted' }" active-class="active-link"
                exact-active-class="exact-active-link">
                <span class="text_header">藤壶业务</span>
              </router-link>
            </el-menu-item>
            <el-menu-item index="1-3" :to="{ path: '/mbNews' }">
              <router-link class="text_header" to="/mbNews"
                v-active-link="{ path: '/mbNews', extraClass: 'highlighted' }" active-class="active-link"
                exact-active-class="exact-active-link">
                <span class="text_header">新闻资讯</span>
              </router-link>
            </el-menu-item>
            <el-menu-item index="1-4" :to="{ path: '/mbAbout' }">
              <router-link class="text_header" to="/mbAbout"
                v-active-link="{ path: '/mbAbout', extraClass: 'highlighted' }" active-class="active-link"
                exact-active-class="exact-active-link">
                <span class="text_header">关于藤壶</span>
              </router-link>
            </el-menu-item>
          </el-menu>
        </template>
      </el-dropdown>
      <div class="text-wrapper_1 flex-col">
        <router-link class="text_6" to="/mbContact" v-active-link="{ path: '/mbContact', extraClass: 'highlighted' }"
          active-class="active-link" exact-active-class="exact-active-link">
          <span class="text_6">联系我们</span>
        </router-link>
      </div>
    </div>
  </div>
</template>


<script setup>
import { useRouter } from 'vue-router'; // 引入useRouter函数，用于操作路由跳转
import { ref } from 'vue'; // 引入useRouter函数，用于操作路由跳转

const router = useRouter(); // 创建路由实例
const showDropdown = ref(false); // 新增一个响应式变量，用于控制下拉菜单的显示隐藏

const goToHomePage = () => {
  router.push('/mbHomePage'); // 定义点击图片时的跳转方法，跳转到首页
};
</script>

<style scoped>
/* 整体布局盒子样式 */
.box {
  box-shadow: 0px 1px 0px 0px rgba(218, 218, 218, 1);
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-wrap: wrap;
  /* 允许内部元素换行，适应不同屏幕宽度 */
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  /* 适当减小内边距，节省空间且更通用 */
  width: 100%;
}

.box_1 {
  width: 100%;
  /* 在小屏幕下先占满整行，后续通过媒体查询调整PC等端的占比 */
  text-align: center;
  /* 让图片在小屏幕下居中显示更美观，可根据需求调整 */
}

.box_2 {
  width: 100%;
  /* 在小屏幕下占满整行，后续通过媒体查询调整占比 */
  display: flex;
  flex-direction: column;
  /* 在手机端让菜单纵向排列，更符合阅读习惯 */
  align-items: center;
  /* 让菜单在手机端水平居中对齐，可根据需求调整 */
}

/* 图片样式 */
.image_1 {
  width: 100px;
  height: 30px;
  margin: 10px auto;
  cursor: pointer;
  float: left;
}

.button-icon {
  width:  25px;
  height: 25px;
  margin-right: 20px;
}


/* 菜单文本通用样式 */
.text_header {
  height: auto;
  /* 高度自适应内容，避免固定高度导致文字显示问题 */
  font-size: 14px;
  /* 适当减小字体大小，更通用，可根据媒体查询再调整 */
  font-family: PingFangSC-Medium;
  font-weight: 600;
  text-align: center;
  /* 在手机端让菜单文字居中对齐，更美观 */
  white-space: nowrap;
  line-height: 1.5;
  /* 合理设置行高，提高可读性 */
  margin: 5px 0;
  /* 适当减小上下外边距，节省空间 */
}

.text-wrapper_1 {
  background-color: rgba(251, 45, 45, 0.1);
  border-radius: 4px;
  height: auto;
  /* 高度自适应内容 */
  width: 80px;
  height: 30px;
  margin: 10px 0;
  /* 适当调整上下外边距 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.text_6 {
  width: auto;
  height: auto;
  font-size: 12px;
  /* 适当减小字体大小 */
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 1.5;
}

a {
  color: inherit;
  /* 让文字颜色继承父元素的颜色 */
  text-decoration: none;
  /* 去除下划线 */
}

.box_1 {
  width: 60%;
  /* 在PC端恢复原有的宽度占比 */
}

.box_2 {
  width: 40%;
  /* 在PC端恢复原有的宽度占比 */
  flex-direction: row;
  /* 在PC端让菜单横向排列 */
}

.text_header {
  font-size: 16px;
  /* 在PC端恢复原有的字体大小 */
  margin-right: 20px;
  /* 在PC端恢复原有的间距 */
}
</style>