<template>
  <div class="about_banner" />
  <div class="about_company">
    <span class="about_company_title">公司介绍</span>
    <span
      class="about_company_content">江苏藤壶数字科技有限公司成立于2024年，一个创新型的数字经济公司。致力于打造消费生活数字平台——藤壶生活圈。运用先进的经济理论和技术手段，整合线上线下资源，为消费者提供全新的消费体验。聚焦消费者核心需求，联合餐饮美食、美容家装、休闲养生等多行业实体商家，构建新型消费服务体系。通过藤壶生活圈平台，消费者能享受更便捷的消费服务，同时实现家庭收入的持续增长和多元化致富。公司以创新引领发展，不断探索数字经济时代的美好生活新路径。</span>
    <div class="about_group_inner_container">
      <div class="about_group">
        <span class="about_group_num">50000+</span>
        <span class="about_group_title">会员数量</span>
      </div>
      <div class="about_group">
        <span class="about_group_num">400+</span>
        <span class="about_group_title">商家数量</span>
      </div>
      <div class="about_group">
        <span class="about_group_num">1000万++</span>
        <span class="about_group_title">总交易额</span>
      </div>
    </div>
  </div>
  <div class="about_model">
    <span class="about_model_title">商业模式</span>
    <div class="about_model_img"></div>
  </div>
  <div class="about_flag">
    <span class="about_flag_title">发展历程</span>
    <div class="about_flag__line"></div> <!-- 添加用于显示灰线的元素 -->
    <div class="about_flag_container">
      <div class="about_flag_item">
        <div class="about_flag_timeline" />
        <span class="about_flag_time">2024年6月18日</span>
        <span class="about_flag_event">公司成立</span>
        <img src="../assets/发展历程-0618.jpg" class="about_flag_image" alt="公司成立相关图片">
      </div>
      <div class="about_flag_item">
        <div class="about_flag_timeline" />
        <span class="about_flag_time">2024年7月1日</span>
        <span class="about_flag_event">公司搬迁到鼓楼区幕府小镇</span>
        <img src="../assets/发展历程-0701.jpg" class="about_flag_image" alt="公司搬迁相关图片">
      </div>
      <div class="about_flag_item">
        <div class="about_flag_timeline" />
        <span class="about_flag_time">2024年8月21日</span>
        <span class="about_flag_event">小程序上线</span>
        <img src="../assets/发展历程-0821.jpg" class="about_flag_image" alt="小程序上线相关图片">
      </div>
      <div class="about_flag_item">
        <div class="about_flag_timeline" />
        <span class="about_flag_time">2024年9月21日</span>
        <span class="about_flag_event">GMV日流水过10万元</span>
        <img src="../assets/发展历程-0921.jpg" class="about_flag_image" alt="GMV日流水过10万元相关图片">
      </div>
      <div class="about_flag_item">
        <div class="about_flag_timeline" />
        <span class="about_flag_time">2024年10月21日</span>
        <span class="about_flag_event">日流水过20万元</span>
        <img src="../assets/发展历程-1021.jpg" class="about_flag_image" alt="日流水过20万元相关图片">
      </div>
      <div class="about_flag_item">
        <div class="about_flag_timeline" />
        <span class="about_flag_time">2024年12月2日</span>
        <span class="about_flag_event">小程序2.0上线</span>
        <img src="../assets/发展历程-1202.jpg" class="about_flag_image" alt="小程序2.0上线相关图片">
      </div>
    </div>
  </div>
  <div class="about_mission">
    <span class="about_mission_title_1">公司使命</span>
    <span class="about_mission_title_2">携手藤壶圈，创造好生活！</span>
    <div class="about_mission_group">
      <div class="about_mission_group_1" @mouseenter="showOpen(1)" @mouseleave="showClose(1)" ref="defOpen">
        <div v-if="showGroup === 1 && isOpen" class="abount_mission_group_open">
          <div class="abount_mission_group_open_content">
            <div>
              <div class="abount_mission_group_open_content_title">企业愿景</div>
              <div class="abount_mission_group_open_content_content">让人们更容易得到实惠！</div>
              <div class="abount_mission_group_open_content_content" style="margin-top: 15px;">——为消费者打造新时代的家庭收入模型！</div>
              <div class="abount_mission_group_open_line" />
            </div>
          </div>
          <div class="abount_mission_group_open_img_1" />
        </div>
        <div v-else class="abount_mission_group_close_1">
          <span class="abount_mission_group_close_text">企业愿景</span>
          <div class="abount_mission_group_close_line" />
        </div>
      </div>
      <div class="about_mission_group_2" @mouseenter="showOpen(2)" @mouseleave="showClose(2)">
        <div v-if="showGroup === 2 && isOpen" class="abount_mission_group_open">
          <div class="abount_mission_group_open_content">
            <div>
              <div class="abount_mission_group_open_content_title">经营理念</div>
              <div class="abount_mission_group_open_content_content">服务民生，创造幸福！(为消费者)</div>
              <div class="abount_mission_group_open_content_content" style="margin-top: 15px;">
                为商家提供更多的消费客户，创造更多的盈利商机。坚持以用户为中心，不断优化创新，以满足用户的需求和期望。</div>
              <div class="abount_mission_group_open_line" />
            </div>
          </div>
          <div class="abount_mission_group_open_img_2" />
        </div>
        <div v-else class="abount_mission_group_close_2">
          <span class="abount_mission_group_close_text">经营理念</span>
          <div class="abount_mission_group_close_line" />
        </div>
      </div>
      <div class="about_mission_group_3" @mouseenter="showOpen(3)" @mouseleave="showClose(3)">
        <div v-if="showGroup === 3 && isOpen" class="abount_mission_group_open">
          <div class="abount_mission_group_open_content">
            <div>
              <div class="abount_mission_group_open_content_title">核心价值观</div>
              <div class="abount_mission_group_open_content_content">踏实--做人脚踏实地 </div>
              <div class="abount_mission_group_open_content_content">勤奋--做事兢兢业业</div>
              <div class="abount_mission_group_open_content_content">创新--运营不断创新</div>
              <div class="abount_mission_group_open_content_content">求真--悟道一贯求真</div>
              <div class="abount_mission_group_open_line" />
              <div class="abount_mission_group_open_img_4" />
            </div>
          </div>
          <div class="abount_mission_group_open_img_3" />
        </div>
        <div v-else class="abount_mission_group_close_3">
          <span class="abount_mission_group_close_text">核心价值观</span>
          <div class="abount_mission_group_close_line" />
        </div>
      </div>
      <div class="about_mission_group_4" @mouseenter="showOpen(4)" @mouseleave="showClose(4)">
        <div v-if="showGroup === 4 && isOpen" class="abount_mission_group_open">
          <div class="abount_mission_group_open_content">
            <div>
              <div class="abount_mission_group_open_content_title">团队精神</div>
              <div class="abount_mission_group_open_content_content">磨难铸就虔诚的信仰，</div>
              <div class="abount_mission_group_open_content_content">求真见证永恒的拥护!</div>
              <div class="abount_mission_group_open_line" />
            </div>
          </div>
          <div class="abount_mission_group_open_img_4" />
        </div>
        <div v-else class="abount_mission_group_close_4">
          <span class="abount_mission_group_close_text">团队精神</span>
          <div class="abount_mission_group_close_line" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

// 用于控制显示哪个具体的分组内容处于open状态，初始值设为1，可根据不同情况改变
const showGroup = ref(1);
// 用于整体控制是显示展开（open）还是收起（close）状态，初始为true表示默认展开（可根据实际需求调整）
const isOpen = ref(true);

// 当鼠标移入对应区域时触发，用于展开对应分组的详细内容
function showOpen(num) {
  showGroup.value = num;
  isOpen.value = true;
  if (num === 1) {
    defOpen.value.style.width = '30%';
  } else {
    defOpen.value.style.width = '15%';
  }
}

// 创建一个ref引用，用于关联模板中的特定元素，初始值为null，等待Vue在渲染时赋值对应的DOM元素
const defOpen = ref(null);

// 当鼠标移出对应区域时触发，用于收起分组内容，并在初次操作时尝试设置特定元素宽度为15%
function showClose(num) {
  isOpen.value = false;
  showGroup.value = num;
  defOpen.value.style.width = '15%';
}


</script>

<style lang="postcss">
@import url('../css/about.css');
</style>
