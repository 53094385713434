import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/pages/homePage.vue';
import BusinessPage from '@/pages/business.vue';
import NewsPage from '@/pages/news.vue';
import NewsDetailPage from '@/pages/newsDetail.vue';
import AboutPage from '@/pages/about.vue';
import ContactPage from '@/pages/contact.vue';

import MbHomePage from '@/pages/mbHomePage.vue';
import MbBusinessPage from '@/pages/mbBusiness.vue';
import MbNewsPage from '@/pages/mbNews.vue';
import MbNewsDetailPage from '@/pages/mbNewsDetail.vue';
import MbAboutPage from '@/pages/mbAbout.vue';
import MbContactPage from '@/pages/mbContact.vue';

// 通过判断userAgent中是否包含常见移动端标识来简单区分移动端设备
const isMobile = () => {
    const mobileKeywords = ['Mobile', 'Android', 'iPhone', 'iPad', 'iPod'];
    return mobileKeywords.some(keyword => navigator.userAgent.includes(keyword));
};

const getRoutePathAndComponent = (path, component) => {
    let x =  isMobile() ? { path: `/mbHomePage`, MbHomePage } :  { path: `/homePage`,  HomePage};
    console.log(x)
    return x;
};

const routes = [
    {
        path: '/',
        redirect: getRoutePathAndComponent('/homePage', HomePage).path
    },
    {
        path: '/homepage',
        component: HomePage
    },
    {
        path: '/business',
        component: BusinessPage
    },
    {
        path: '/news',
        component: NewsPage
    },
    {
        path: '/newsDetail/:cultureData',
        name: 'newsDetail',
        component: NewsDetailPage
    },
    {
        path: '/about',
        component: AboutPage
    },
    {
        path: '/contact',
        component: ContactPage
    },
    {
        path: '/mbHomepage',
        component: MbHomePage
    },
    {
        path: '/mbBusiness',
        component: MbBusinessPage
    },
    {
        path: '/mbNews',
        component: MbNewsPage
    },
    {
        path: '/mbNewsDetail/:cultureData',
        name: 'mbNewsDetail',
        component: MbNewsDetailPage
    },
    {
        path: '/mbAbout',
        component: MbAboutPage
    },
    {
        path: '/mbContact',
        component: MbContactPage
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

// 使用全局后置守卫，在每次路由跳转完成后执行
router.afterEach((to, from) => {
    window.scrollTo(0, 0);
});

export default router;