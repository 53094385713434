import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index.js'; // 这里已经导入了路由实例，不需要重复创建了
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';


const app = createApp(App);
// const BASE_URL = 'http://localhost:8778/';
const BASE_URL = 'https://api.jsthq.com/th2-api';
app.config.globalProperties.$BASE_URL = BASE_URL;
app.use(router);
app.use(ElementPlus);
app.mount('#app');