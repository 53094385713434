<template>
  <div class="box">
    <div class="content">
      <div class="box_1">
        <div class="box_1_1">
          <img class="image_1" referrerpolicy="no-referrer" src="../../assets/foot logo.png" />
        </div>
        <div class="container">
          <img class="thumbnail_1" referrerpolicy="no-referrer" src="../../assets/foot 地址.png" />
          <span class="paragraph_1">客服热线：18061690899<br /></span>
        </div>
        <div class="container">
          <img class="thumbnail_1" referrerpolicy="no-referrer" src="../../assets/foot 电话.png" />
          <span class="paragraph_1"> 公司地址：南京市鼓楼区幕府创新小镇A15栋</span>
        </div>
      </div>
      <div class="box_2">
        <span class="text_link_head">网站导航</span>
        <div class="nav-links">
          <div class="link-row">
            <router-link class="text_link" to="/mbHomePage">
              <span class="text_2">藤壶首页</span>
            </router-link>
            <router-link class="text_link" to="/mbBusiness">
              <span class="text_2">藤壶业务</span>
            </router-link>
            <router-link class="text_link" to="/mbNews">
              <span class="text_2">新闻资讯</span>
            </router-link>

            <router-link class="text_link" to="/mbAbout">
              <span class="text_2">关于藤壶</span>
            </router-link>
            <router-link class="text_link" to="/mbContact">
              <span class="text_2">联系我们</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="box_3">
        <img class="image_2" referrerpolicy="no-referrer" src="../../assets/foot 公众号.jpg" />
        <img class="image_2" style="margin-left: 20px;" referrerpolicy="no-referrer" src="../../assets/foot 小程序.png" />
      </div>
    </div>
    <footer class="foot">
      <span class="text_2">
        江苏藤壶数字科技有限公司版权所有&nbsp; <a href="https://beian.miit.gov.cn/" target="_blank"
          rel="noopener noreferrer">苏ICP备2024113901号</a>
      </span>
    </footer>
  </div>
</template>
<style scoped>
.text_link_head {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  text-align: left;
}

.text_link {
  width: auto;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 5px;
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  margin-right: 15px;
  /* 每个链接之间增加一点间隔 */
}

.container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.content {
  display: flex;
  flex-direction: column;
}

.box {
  box-shadow: 0px 1px 0px 0px rgba(218, 218, 218, 1);
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  background-color: rgba(243, 243, 243, 1);
  width: 100%;
}

.paragraph_1 {
  width: auto;
  height: auto;
  overflow-wrap: break-word;
  color: rgba(41, 34, 34, 1);
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  line-height: 1.5;
}

.image_1 {
  max-width: 160px;
  max-height: 160px;
  width: auto;
  height: auto;
  display: block;
  margin-bottom: 10px;
}

.image_2 {
  width: 117px;
  height: 118px;
  margin: 6px 0 0 7px;
}

.thumbnail_1 {
  width: 13px;
  height: 13px;
  margin-top: 0px;
}

.box_1_1 {
  margin-left: 0;
}

.box_1 {
  width: 100%;
  margin-left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-gap: 10px;
  margin-top: 20px;
}

.box_2 {
  width: 100%;
  margin-top: 20px;
}

.box_3 {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.foot {
  width: 100%;
  text-align: center;
}

.text_2 {
  width: auto;
  height: auto;
  overflow-wrap: break-word;
  color: rgba(158, 158, 158, 1);
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  line-height: 1.5;
}

.nav-links {
  display: flex;
  flex-wrap: wrap;
  /* 允许内部元素换行，实现两行排列 */
}

.link-row {
  width: 100%;
  display: flex;
  /* 让内部链接在一行内按行排列 */
}
</style>