<template>
  <div class="box">
    <div class="content">
      <div class="box_1">
        <div class="box_1_1">
          <img class="image_1" referrerpolicy="no-referrer" src="../assets/foot logo.png" />
          <div class="container">
            <img class="thumbnail_1" referrerpolicy="no-referrer" src="../assets/foot 地址.png" />
            <span class="paragraph_1">客服热线：18061690899<br /></span>
          </div>
          <div class="container">
            <img class="thumbnail_1" referrerpolicy="no-referrer" src="../assets/foot 电话.png" />
            <span class="paragraph_1"> 公司地址：南京市鼓楼区幕府创新小镇A15栋</span>
          </div>
        </div>
      </div>
      <div class="box_2">
        <span class="text_link_head">网站导航</span>
        <router-link class="text_link" to="/homePage">
          <span class="text_2">藤壶首页</span>
        </router-link>
        <router-link class="text_link" to="/business">
          <span class="text_2">藤壶业务</span>
        </router-link>
        <router-link class="text_link" to="/news">
          <span class="text_2">新闻资讯</span>
        </router-link>
        <router-link class="text_link" to="/about">
          <span class="text_2">关于藤壶</span>
        </router-link>
        <router-link class="text_link" to="/contact">
          <span class="text_2">联系我们</span>
        </router-link>
        <router-link class="text_link" to="/contact">
        </router-link>
      </div>
      <div class="box_3">
        <img class="image_2" referrerpolicy="no-referrer"
          src="../assets/foot 公众号.jpg" />
        <img class="image_2" style="margin-left: 20px;" referrerpolicy="no-referrer" src="../assets/foot 小程序.png" />
      </div>
    </div>
    <footer class="foot">
      <span class="text_2">
        江苏藤壶数字科技有限公司版权所有&nbsp; <a href="https://beian.miit.gov.cn/" target="_blank"
          rel="noopener noreferrer">苏ICP备2024113901号</a>
      </span>
    </footer>
  </div>
</template>

<script setup>

</script>

<style scoped>
.text_link_head {
  width: 47%;
  /* 每个链接大概占一半宽度，可根据实际情况微调 */
  font-size: 16px;
  font-weight: bold;
  display: block;
  /* 让其单独占一行 */
  margin-bottom: 10px;
  /* 设置与下方元素的间隔距离，可按需调整 */
}

.text_link {
  width: 45%;
  /* 每个链接大概占一半宽度，可根据实际情况微调 */
  box-sizing: border-box;
  /* 确保宽度包含内边距和边框，避免布局错乱 */
  display: inline-block;
  margin-bottom: 10px;
  /* 每个链接上下间隔距离，可调整 */
  text-decoration: none;
  /* 去除a标签默认的下划线 */
  color: inherit;
  /* 让a标签文本颜色继承父元素的颜色，去除默认蓝色 */
  font-size: 14px;
}

.text_link:nth-of-type(2n) {
  margin-left: 10%;
  /* 每两个一组中的第二个元素设置左边距，使其换行对齐更整齐 */
}


.container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  /* 让内部元素在垂直方向上居中对齐 */
}

.content {
  display: flex;
  flex-wrap: wrap;
  /* 允许内部元素换行，适应不同屏幕宽度 */
}

.box {
  box-shadow: 0px 1px 0px 0px rgba(218, 218, 218, 1);
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  background-color: rgba(243, 243, 243, 1);
  width: 100%;
}

.paragraph_1 {
  width: auto;
  /* 宽度自适应内容，避免固定宽度导致显示问题 */
  height: auto;
  /* 高度自适应内容，防止文字过多时溢出等情况 */
  overflow-wrap: break-word;
  color: rgba(41, 34, 34, 1);
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  line-height: 1.5;
}

.image_1 {
  max-width: 160px;
  /* 设置最大宽度，在不同屏幕下合理控制图片大小 */
  max-height: 160px;
  width: auto;
  height: auto;
  display: block;
}

.image_2 {
  width: 117px;
  height: 118px;
  margin: 6px 0 0 7px;
}

.thumbnail_1 {
  width: 13px;
  height: 13px;
  margin-top: 5px;
  /* 适当调整上边距 */
}

.box_1_1 {
  margin-left: 120px;
}

.box_1 {
  width: 45%;
  /* 在PC端占一定比例宽度，可根据实际情况调整 */
  margin-left: 0;
  /* 去除可能影响布局的固定左边距 */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto auto;
  grid-gap: 10px;
  margin-top: 20px;
}

.box_2 {
  width: 20%;
  /* 在PC端占一定比例宽度，可根据实际情况调整 */
  margin-top: 20px;
}

.box_3 {
  width: 20%;
  /* 在PC端占一定比例宽度，可根据实际情况调整 */
  margin-top: 20px;
  display: flex;
}

.foot {
  width: 100%;
  text-align: center;
  /* 让版权信息居中显示，更美观 */
}

.text_1 {
  width: auto;
  height: auto;
  overflow-wrap: break-word;
  color: rgba(41, 34, 34, 1);
  font-size: 18px;
  font-family: qiantuxianmoti;
  font-weight: normal;
  text-align: left;
  line-height: 1.5;
  margin-top: 10px;
  display: block;
}


.text_2 {
  width: auto;
  height: auto;
  overflow-wrap: break-word;
  color: rgba(158, 158, 158, 1);
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  line-height: 1.5;
}

.nav-links {
  display: flex;
  flex-direction: column;
}

.link-item {
  margin-right: 10px;
  color: inherit;
  text-decoration: none;
}
</style>