<template>
    <div class="mbcontact_banner"/>

    <div class="mbcontact_body_container">
        <span class="mbmap_text">联系我们</span>
        <div class="mbcontact_body_inner_container"> <!-- 新增的内部容器 -->
            <div class="mbcontact_body">
                <div class="mbcontact_group">
                    <img class="mbcontact_img" src="../assets/联系我们-客服电话.png">
                    <span class="mbcontact_title">客服电话</span>
                    <span class="mbcontact_text">18061690899  </span>
                    <p  class="mbcontact_text_time">工作时间: 09:00-20:00</p>
                </div>
            </div>
            <div class="mbcontact_body">
                <div class="mbcontact_group">
                    <img class="mbcontact_img" src="../assets/联系我们-商务合作.png">
                    <span class="mbcontact_title">商务合作</span>
                    <span class="mbcontact_text">TEHGHUKEJI@163.com</span> 
                </div>
            </div>
        </div>
    </div>

    <div class="mbmap">
        <span class="mbmap_text">联系地址</span>
        <div id="mapContainer">南京市鼓楼区幕府创新小镇A15栋</div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import $ from 'jquery'; // 假设已经正确安装了jQuery，通过npm或yarn安装后导入

export default {
    setup() {
        let map = ref(null); // 用来存放地图实例对象，定义为响应式数据方便后续可能的更新操作
        let point = ref({ lat: 32.128308, lng: 118.809156 }); // 地图初始中心点坐标，定义为响应式数据
        onMounted(() => {
            // 初始化地图
            map.value = new BMap.Map("mapContainer", {
                coordsType: 5
            });
            const initPoint = new BMap.Point(point.value.lng, point.value.lat);
            map.value.centerAndZoom(initPoint, 16);
            map.value.enableScrollWheelZoom(true);

            // 可以添加地图标记等其他地图相关元素初始化操作示例（这里添加一个简单标记）
            const marker = new BMap.Marker(initPoint);
            map.value.addOverlay(marker);


        });

        return {
        };
    }
};
</script>
<style lang="postcss">
@import url('../css/mb/contact.css');
</style>