<template>
  <div>
    <div class="scroll">
      <div class="image-wrapper_1 flex-col" :class="{ 'show-qrcode': showCustomerServiceQrcode }">
        <img :src="customerServiceSrc" class="scroll-img" @mouseenter="handleMouseEnter('customerService')"
          @mouseleave="handleMouseLeave('customerService')" style="height: 75px;width: 75px;">
        <img v-if="showCustomerServiceQrcode"
          src="../assets/客服二维码.png"
          class="qrcode-img" @mouseleave="hideCustomerServiceQrcode">
      </div>
      <div class="image-wrapper_2 flex-col" :class="{ 'show-qrcode': showMiniProgramQrcode }">
        <img :src="miniProgramSrc" class="scroll-img" @mouseenter="handleMouseEnter('miniProgram')"
          @mouseleave="handleMouseLeave('miniProgram')"  style="height: 75px;width: 75px;">
        <img v-if="showMiniProgramQrcode" src="../assets/foot 小程序.png" class="qrcode-img"
          @mouseleave="hideMiniProgramQrcode">
      </div>
      <div class="image-wrapper_3 flex-col">
        <img :src="feedbackSrc" class="scroll-img" @click="visible = true" @mouseenter="handleMouseEnter('feedback')"
          @mouseleave="handleMouseLeave('feedback')"   style="height: 75px;width: 75px;">
      </div>
      <div class="image-wrapper_4 flex-col">
        <img :src="topSrc" class="scroll-img"  @mouseenter="handleMouseEnter('top')" @mouseleave="handleMouseLeave('top')"
          @click="scrollToTop"  style="height: 75px;width: 75px;">
      </div>
    </div>
    <div class="homepage_banner" />
    <div class="group">
      <div class="group_1">
        <img class="group_img" referrerpolicy="no-referrer" src="@/assets/首页-绿色消费积分.png" />
        <div class="text_groups">
          <span class="text_1">绿色消费积分</span>
          <span class="text_2">积极响应国家倡导绿色消费积分制</span>
        </div>
      </div>
      <div class="group_2">
        <img class="group_img" referrerpolicy="no-referrer" src="@/assets/首页-智能营销收款码.png" />
        <div class="text_groups">
          <span class="text_1">智能营销收款码</span>
          <span class="text_2">免费提供收款码，增加顾客粘性</span>
        </div>
      </div>
      <div class="group_3">
        <img class="group_img" referrerpolicy="no-referrer" src="@/assets/首页-消费赚收益.png" />
        <div class="text_groups">
          <span class="text_1">消费赚收益</span>
          <span class="text_2">用户消费获得全额使用抵用券</span>
        </div>
      </div>
    </div>
    <div class="company">
      <div class="company_title">
        <span>藤壶介绍</span>
      </div>
      <div class="company_body">
        <div class="company_img">
        </div>
        <div class="company_text">
          <span class="company_name">江苏藤壶数字科技有限公司</span>
          <span class="company_english_name">Jiangsu&nbsp;Tenghu&nbsp;Digital&nbsp;Technology&nbsp;Co.,&nbsp;Ltd</span>
          <span
            class="company_description">江苏藤壶数字科技有限公司成立于2024年，一个创新型的数字经济公司。致力于打造消费生活数字平台——藤壶生活圈。运用先进的经济理论和技术手段，整合线上线下资源，为消费者提供全新的消费体验。聚焦消费者核心需求，联合...</span>
          <router-link class="router_btn" to="/about">
            <span>查看详情</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="business">
      <div class="business_body">
        <div class="business_text">
          <span class="business_name">藤壶生活圈小程序</span>
          <span class="business_description">入驻商户超400家，商务团队超200人</span>
          <router-link class="router_btn" to="/business">
            <span>查看详情</span>
          </router-link>
        </div>
        <div class="business_img">
        </div>
      </div>
    </div>
    <el-dialog v-model="visible" title="意见反馈" class="feedback-dialog">
      <el-form :model="feedbackForm" ref="feedbackFormRef" class="feedback-form" :rules="rules">
        <el-form-item label="您的姓名" class="feedback-form-item" prop="userName">
          <el-input v-model="feedbackForm.userName" placeholder="请输入称呼" class="feedback-input" />
        </el-form-item>
        <el-form-item label="联系方式" class="feedback-form-item" prop="phoneNumber">
          <el-input v-model="feedbackForm.phoneNumber" placeholder="请输入联系方式" class="feedback-input" />
        </el-form-item>
        <el-form-item label="反馈内容" class="feedback-form-item" prop="feedbackContent">
          <el-input v-model="feedbackForm.feedbackContent" :rows="12" type="textarea" placeholder="请输入反馈内容"
            class="feedback-textarea" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="popup-footer">
        <el-button color="#FB2D2D" size="large" round @click="submitFeedback" class="feedbackBtn">提交</el-button>
      </div>
    </el-dialog>
    <div class="culture">
      <div class="culture_title">
        <span>新闻资讯</span>
      </div>
      <div class="culture_groups">
        <div class="culture_group" v-for="culture in companyCultures" :key="culture.id">
          <img :src="culture.thumbnail" class="culture_img">
          <span class="culture_text_title">{{ culture.title }}</span>
          <span class="culture_text_content" v-html="processContent(culture.content)"></span>
          <router-link class="newDetailBtn"
            :to="{ name: 'newsDetail', params: { cultureData: JSON.stringify(culture) } }">
            <span>查看详情</span>
          </router-link>
        </div>
      </div>
      <div class="culture_btn">
        <router-link class="router_btn" to="/news">
          <span>查看更多</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// 引入未选中状态的各个图标图片资源
import customerService from '@/assets/客服1.png';
import miniProgram from '@/assets/小程序1.png';
import feedback from '@/assets/反馈1.png';
import top from '@/assets/置顶1.png';
// 引入选中状态的各个图标图片资源
import customerServiceHover from '@/assets/客服2.png';
import miniProgramHover from '@/assets/小程序2.png';
import feedbackHover from '@/assets/反馈2.png';
import topHover from '@/assets/置顶2.png';
import axios from 'axios';
import { ElMessage } from 'element-plus'

import { ref, computed, onMounted, defineEmits, reactive,getCurrentInstance  } from 'vue';

export default {
  setup() {
    const { proxy } = getCurrentInstance();

    const visible = ref(false);
    // 使用reactive创建响应式的feedbackForm对象，方便表单数据双向绑定及后续操作
    const feedbackForm = reactive({
      userName: '',
      phoneNumber: '',
      feedbackContent: ''
    });
    // 用于记录客服图标是否被选中的响应式数据
    const customerServiceSelected = ref(false);
    // 用于记录小程序图标是否被选中的响应式数据
    const miniProgramSelected = ref(false);
    // 用于记录意见反馈图标是否被选中的响应式数据
    const feedbackSelected = ref(false);
    // 用于记录置顶图标是否被选中的响应式数据
    const topSelected = ref(false);
    // 用于控制客服二维码是否显示的响应式数据
    const showCustomerServiceQrcode = ref(false);
    // 用于控制小程序二维码是否显示的响应式数据
    const showMiniProgramQrcode = ref(false);

    // 定义表单验证规则
    const rules = {
      userName: [
        { required: true, message: '请输入您的姓名', trigger: 'blur' }
      ],
      phoneNumber: [
        { required: true, message: '请输入联系方式', trigger: 'blur' }
      ],
      feedbackContent: [
        { required: true, message: '请输入反馈内容', trigger: 'blur' }
      ]
    };

    // 初始化feedbackFormRef，用于获取el-form组件实例
    const feedbackFormRef = ref(null);

    // 计算属性，根据客服图标是否被选中返回对应的图标资源路径（选中返回选中状态的图标，未选中返回未选中状态的图标）
    const customerServiceSrc = computed(() =>
      customerServiceSelected.value ? customerServiceHover : customerService
    );
    // 计算属性，根据小程序图标是否被选中返回对应的图标资源路径（选中返回选中状态的图标，未选中返回未选中状态的图标）
    const miniProgramSrc = computed(() =>
      miniProgramSelected.value ? miniProgramHover : miniProgram
    );
    // 计算属性，根据意见反馈图标是否被选中返回对应的图标资源路径（选中返回选中状态的图标，未选中返回未选中状态的图标）
    const feedbackSrc = computed(() =>
      feedbackSelected.value ? feedbackHover : feedback
    );
    // 计算属性，根据置顶图标是否被选中返回对应的图标资源路径（选中返回选中状态的图标，未选中返回未选中状态的图标）
    const topSrc = computed(() =>
      topSelected.value ? topHover : top
    );

    // 使用 defineEmits 定义可抛出的事件
    const emit = defineEmits(['open-feedback-popup']);

    function processContent(content) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, 'text/html');
      const pElements = doc.querySelectorAll('p');

      if (pElements.length > 0) {
        const firstPElement = pElements[0];
        const text = firstPElement.textContent.trim().replace(/\s+/g, '');
        if (text.length > 40) {
          const truncatedText = text.slice(0, 40) + '...';
          return truncatedText;
        }

      }
      return content;
    }

    const companyCultures = ref([])

    // 打开反馈弹窗的方法，补充具体的事件抛出逻辑等内容
    function openFeedbackPopup() {
      emit('open-feedback-popup');
    }

    function submitFeedback() {
      feedbackFormRef.value.validate((valid) => {
        if (valid) {
          let fromData = new FormData;
          fromData = {
            userName: feedbackForm.userName,
            phoneNumber: feedbackForm.phoneNumber,
            feedbackContent: feedbackForm.feedbackContent
          }
          // axios.post(proxy.$BASE_URL + '/feedbackManagement/insert', fromData)
          axios.post(proxy.$BASE_URL + '/feedbackManagement/insert', fromData)
            .then(response => {
              ElMessage({
                message: '提交成功,感谢您的反馈',
                type: 'success',
              })
              // 提交成功后清空表单数据
              feedbackForm.userName = '';
              feedbackForm.phoneNumber = '';
              feedbackForm.feedbackContent = '';
              // 关闭弹窗
              visible.value = false;
              // 也可以在这里添加一些提示用户提交成功的其他逻辑，比如弹出提示框等
            })
            .catch(error => {
              ElMessage.error('提交失败')
              // 可以在这里添加一些提示用户提交失败的逻辑，比如弹出提示框展示错误信息等
            });
        } else {
          return false;
        }
      });
    }

    // 处理鼠标进入图标时的逻辑，根据传入的图标名称切换对应图标的选中状态以及显示相关二维码（如果有）
    function handleMouseEnter(imageName) {
      switch (imageName) {
        case 'customerService':
          customerServiceSelected.value = true;
          showCustomerServiceQrcode.value = true;
          break;
        case 'miniProgram':
          miniProgramSelected.value = true;
          showMiniProgramQrcode.value = true;
          break;
        case 'feedback':
          feedbackSelected.value = true;
          break;
        case 'top':
          topSelected.value = true;
          break;
      }
    }
    // 处理鼠标离开图标时的逻辑，根据传入的图标名称切换对应图标的选中状态以及隐藏相关二维码（如果有）
    function handleMouseLeave(imageName) {
      switch (imageName) {
        case 'customerService':
          customerServiceSelected.value = false;
          showCustomerServiceQrcode.value = false;
          break;
        case 'miniProgram':
          miniProgramSelected.value = false;
          showMiniProgramQrcode.value = false;
          break;
        case 'feedback':
          feedbackSelected.value = false;
          break;
        case 'top':
          topSelected.value = false;
          break;
      }
    }
    // 隐藏客服二维码的方法，将控制客服二维码显示的响应式数据设为 false
    function hideCustomerServiceQrcode() {
      showCustomerServiceQrcode.value = false;
    }
    // 隐藏小程序二维码的方法，将控制小程序二维码显示的响应式数据设为 false
    function hideMiniProgramQrcode() {
      showMiniProgramQrcode.value = false;
    }

    // 定义回到页面顶部的方法
    function scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'  // 可以添加平滑滚动效果，若不需要可去掉这行
      });
    }

    // 在组件挂载完成后，确认feedbackFormRef已关联到el-form组件实例，若未关联则打印错误信息
    onMounted(() => {
      let data = {
        pageNum: 1,
        pageSize: 3,
        orderByColumn: "createdAt",
        isAsc: 'asc'
      }
      axios.get(proxy.$BASE_URL + '/companyCulture/list', data)
        .then(response => {
          const resultData = response.data.data.result;
          // 将获取到的数据赋值给companyCultures
          companyCultures.value = resultData;
        })
        .catch(error => {
          ElMessage.error('提交失败')
          // 可以在这里添加一些提示用户提交失败的逻辑，比如弹出提示框展示错误信息等
        });
    });

    return {
      customerServiceSelected,
      miniProgramSelected,
      feedbackSelected,
      topSelected,
      showCustomerServiceQrcode,
      showMiniProgramQrcode,
      customerServiceSrc,
      miniProgramSrc,
      feedbackSrc,
      topSrc,
      handleMouseEnter,
      handleMouseLeave,
      hideCustomerServiceQrcode,
      hideMiniProgramQrcode,
      openFeedbackPopup,
      scrollToTop,
      visible,
      feedbackForm,
      rules,
      submitFeedback,
      feedbackFormRef,
      companyCultures,
      processContent
    };
  }
};
</script>

<style lang="css">
@import url('../css/homePage.css');
</style>