<template>
  <div>
    <div class="mbhomepage_img1" />
    <div class="mbhomepage_img2">
      <div class="mbabout_btn">
        <router-link class="mbrouter_btn" to="/mbAbout">
          <span>查看更多</span>
        </router-link>
      </div>
    </div>
    <div class="mbhomepage_img3">
      <div class="mbbusiness_btn">
        <router-link class="mbrouter_btn" to="/mbBusiness">
          <span>查看更多</span>
        </router-link>
      </div>
    </div>


    <div class="mbculture">
      <div class="mbculture_title">
        <span>新闻资讯</span>
      </div>
      <div class="mbculture_groups">
        <div class="mbculture_group" v-for="culture in companyCultures" :key="culture.id">
          <img :src="culture.thumbnail" class="mbculture_img">
          <span class="mbculture_text_title">{{ processTitle(culture.title) }}</span>
          <span class="mbculture_text_content" v-html="processContent(culture.content)"></span>
          <router-link class="mbnewDetailBtn"
            :to="{ name: 'mbNewsDetail', params: { cultureData: JSON.stringify(culture) } }">
            <span>查看详情</span>
          </router-link>
        </div>
      </div>
      <div class="mbculture_btn">
        <router-link class="mbrouter_btn" to="/mbNews">
          <span>查看更多</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// 引入未选中状态的各个图标图片资源
import customerService from '@/assets/客服1.png';
import miniProgram from '@/assets/小程序1.png';
import feedback from '@/assets/反馈1.png';
import top from '@/assets/置顶1.png';
// 引入选中状态的各个图标图片资源
import customerServiceHover from '@/assets/客服2.png';
import miniProgramHover from '@/assets/小程序2.png';
import feedbackHover from '@/assets/反馈2.png';
import topHover from '@/assets/置顶2.png';
import axios from 'axios';
import { ElMessage } from 'element-plus'

import { ref, computed, onMounted, defineEmits, reactive, getCurrentInstance } from 'vue';

export default {
  setup() {
    const { proxy } = getCurrentInstance();

    const visible = ref(false);
    // 使用reactive创建响应式的feedbackForm对象，方便表单数据双向绑定及后续操作
    const feedbackForm = reactive({
      userName: '',
      phoneNumber: '',
      feedbackContent: ''
    });
    // 用于记录客服图标是否被选中的响应式数据
    const customerServiceSelected = ref(false);
    // 用于记录小程序图标是否被选中的响应式数据
    const miniProgramSelected = ref(false);
    // 用于记录意见反馈图标是否被选中的响应式数据
    const feedbackSelected = ref(false);
    // 用于记录置顶图标是否被选中的响应式数据
    const topSelected = ref(false);
    // 用于控制客服二维码是否显示的响应式数据
    const showCustomerServiceQrcode = ref(false);
    // 用于控制小程序二维码是否显示的响应式数据
    const showMiniProgramQrcode = ref(false);

    // 定义表单验证规则
    const rules = {
      userName: [
        { required: true, message: '请输入您的姓名', trigger: 'blur' }
      ],
      phoneNumber: [
        { required: true, message: '请输入联系方式', trigger: 'blur' }
      ],
      feedbackContent: [
        { required: true, message: '请输入反馈内容', trigger: 'blur' }
      ]
    };

    // 初始化feedbackFormRef，用于获取el-form组件实例
    const feedbackFormRef = ref(null);

    // 计算属性，根据客服图标是否被选中返回对应的图标资源路径（选中返回选中状态的图标，未选中返回未选中状态的图标）
    const customerServiceSrc = computed(() =>
      customerServiceSelected.value ? customerServiceHover : customerService
    );
    // 计算属性，根据小程序图标是否被选中返回对应的图标资源路径（选中返回选中状态的图标，未选中返回未选中状态的图标）
    const miniProgramSrc = computed(() =>
      miniProgramSelected.value ? miniProgramHover : miniProgram
    );
    // 计算属性，根据意见反馈图标是否被选中返回对应的图标资源路径（选中返回选中状态的图标，未选中返回未选中状态的图标）
    const feedbackSrc = computed(() =>
      feedbackSelected.value ? feedbackHover : feedback
    );
    // 计算属性，根据置顶图标是否被选中返回对应的图标资源路径（选中返回选中状态的图标，未选中返回未选中状态的图标）
    const topSrc = computed(() =>
      topSelected.value ? topHover : top
    );

    // 使用 defineEmits 定义可抛出的事件
    const emit = defineEmits(['open-feedback-popup']);

    function processTitle(title){
      if (title.length > 15) {
        return title.substring(0, 15) + '...';
      }
      return title;
    }

    function processContent(content) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, 'text/html');
      const pElements = doc.querySelectorAll('p');

      if (pElements.length > 0) {
        const firstPElement = pElements[0];
        const text = firstPElement.textContent.trim().replace(/\s+/g, '');
        if (text.length > 40) {
          const truncatedText = text.slice(0, 40) + '...';
          return truncatedText;
        }

      }
      return content;
    }

    const companyCultures = ref([])

    // 打开反馈弹窗的方法，补充具体的事件抛出逻辑等内容
    function openFeedbackPopup() {
      emit('open-feedback-popup');
    }

    function submitFeedback() {
      feedbackFormRef.value.validate((valid) => {
        if (valid) {
          let fromData = new FormData;
          fromData = {
            userName: feedbackForm.userName,
            phoneNumber: feedbackForm.phoneNumber,
            feedbackContent: feedbackForm.feedbackContent
          }
          // axios.post(proxy.$BASE_URL + '/feedbackManagement/insert', fromData)
          axios.post(proxy.$BASE_URL + '/feedbackManagement/insert', fromData)
            .then(response => {
              ElMessage({
                message: '提交成功,感谢您的反馈',
                type: 'success',
              })
              // 提交成功后清空表单数据
              feedbackForm.userName = '';
              feedbackForm.phoneNumber = '';
              feedbackForm.feedbackContent = '';
              // 关闭弹窗
              visible.value = false;
              // 也可以在这里添加一些提示用户提交成功的其他逻辑，比如弹出提示框等
            })
            .catch(error => {
              ElMessage.error('提交失败')
              // 可以在这里添加一些提示用户提交失败的逻辑，比如弹出提示框展示错误信息等
            });
        } else {
          return false;
        }
      });
    }

    // 处理鼠标进入图标时的逻辑，根据传入的图标名称切换对应图标的选中状态以及显示相关二维码（如果有）
    function handleMouseEnter(imageName) {
      switch (imageName) {
        case 'customerService':
          customerServiceSelected.value = true;
          showCustomerServiceQrcode.value = true;
          break;
        case 'miniProgram':
          miniProgramSelected.value = true;
          showMiniProgramQrcode.value = true;
          break;
        case 'feedback':
          feedbackSelected.value = true;
          break;
        case 'top':
          topSelected.value = true;
          break;
      }
    }
    // 处理鼠标离开图标时的逻辑，根据传入的图标名称切换对应图标的选中状态以及隐藏相关二维码（如果有）
    function handleMouseLeave(imageName) {
      switch (imageName) {
        case 'customerService':
          customerServiceSelected.value = false;
          showCustomerServiceQrcode.value = false;
          break;
        case 'miniProgram':
          miniProgramSelected.value = false;
          showMiniProgramQrcode.value = false;
          break;
        case 'feedback':
          feedbackSelected.value = false;
          break;
        case 'top':
          topSelected.value = false;
          break;
      }
    }
    // 隐藏客服二维码的方法，将控制客服二维码显示的响应式数据设为 false
    function hideCustomerServiceQrcode() {
      showCustomerServiceQrcode.value = false;
    }
    // 隐藏小程序二维码的方法，将控制小程序二维码显示的响应式数据设为 false
    function hideMiniProgramQrcode() {
      showMiniProgramQrcode.value = false;
    }

    // 定义回到页面顶部的方法
    function scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'  // 可以添加平滑滚动效果，若不需要可去掉这行
      });
    }

    // 在组件挂载完成后，确认feedbackFormRef已关联到el-form组件实例，若未关联则打印错误信息
    onMounted(() => {
      let data = {
        pageNum: 1,
        pageSize: 2,
        orderByColumn: "createdAt",
        isAsc: 'asc'
      }
      axios.get(proxy.$BASE_URL + '/companyCulture/list', data)
        .then(response => {
          const resultData = response.data.data.result;
          // 将获取到的数据赋值给companyCultures
          companyCultures.value = resultData;
        })
        .catch(error => {
          ElMessage.error('提交失败')
          // 可以在这里添加一些提示用户提交失败的逻辑，比如弹出提示框展示错误信息等
        });
    });

    return {
      customerServiceSelected,
      miniProgramSelected,
      feedbackSelected,
      topSelected,
      showCustomerServiceQrcode,
      showMiniProgramQrcode,
      customerServiceSrc,
      miniProgramSrc,
      feedbackSrc,
      topSrc,
      handleMouseEnter,
      handleMouseLeave,
      hideCustomerServiceQrcode,
      hideMiniProgramQrcode,
      openFeedbackPopup,
      scrollToTop,
      visible,
      feedbackForm,
      rules,
      submitFeedback,
      feedbackFormRef,
      companyCultures,
      processContent,
      processTitle
    };
  }
};
</script>

<style lang="css">
@import url('../css/mb/homePage.css');
</style>