<template>
  <component :is="headerComponent" class="header" />
  <RouterView class="view" />
  <component :is="footComponent" class="foot" />
</template>

<script setup>
import HelloWorld from './pages/homePage.vue';
// 动态导入桌面端的Header组件
import DesktopHeader from './components/header.vue';
// 动态导入桌面端的Foot组件
import DesktopFoot from './components/foot.vue';
// 动态导入移动端的Header组件
import MbHeader from './components/mb/header.vue';
// 动态导入移动端的Foot组件
import MbFoot from './components/mb/foot.vue';

import { ref, onMounted } from 'vue';

const headerComponent = ref(null);
const footComponent = ref(null);

onMounted(() => {
  const userAgent = navigator.userAgent;
  const isMobile = /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  if (isMobile) {
    headerComponent.value = MbHeader;
    footComponent.value = MbFoot;
  } else {
    headerComponent.value = DesktopHeader;
    footComponent.value = DesktopFoot;
  }
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.header {
  position: fixed; /* 将导航栏的定位方式设置为固定定位 */
  top: 0; /* 距离页面顶部的距离为0，使其固定在顶部 */
  left: 0; /* 距离页面左侧的距离为0，可根据需求调整水平位置，这里设置为最左边 */
  right: 0; /* 距离页面右侧的距离为0，确保在水平方向占满整个页面宽度 */
  z-index: 999; /* 设置一个较高的z-index值，确保导航栏在其他元素之上显示，避免被覆盖，可根据实际情况调整数值 */
}

.view {
  margin-top: 40px; 
}

</style>